<template>
  <v-snackbar
    v-model="snackbar"
    :multi-line="multiLine"
    :color="color"
  >    
    <span class="notify-text">{{ text }}</span>
    <template v-slot:action="{ attrs }">
      <v-btn text color="#FFFFF" center v-bind="attrs" @click="closeNotify">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    },
    color: {
      type: String
    },
    show: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    multiLine: true,
    snackbar: false
  }),
  mounted() {
    this.snackbar = this.show  
  }, 
  methods: {
    closeNotify(){
      this.snackbar = false
      this.$emit('closeNotify', false)
    }
  }
}
</script>

<style scoped>
.notify-text {
  font-size: 1.5em;
  text-align: center;
}
</style>