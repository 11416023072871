<template>
   <v-overlay :opacity="opacity">
     <div>Please, wait...</div>
     <v-progress-circular
       indeterminate
       size="64"
     ></v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  data: () => ({
    opacity: 0.8
  }),
}
</script>