<template>
  <div class="wrapper">
    <h1>My Profile</h1>
    <v-form ref="form">
      <profile-header :text="form.name" />

      <v-row>
        <v-col cols="7" class="fields">
          <v-text-field
            label="Full Name"
            maxlength="40"
            v-model="form.fullname"
            outlined
            dense
            requried
          />
          <v-text-field label="Nick name" v-model="form.name" outlined dense />
          <v-text-field label="Email" v-model="form.email" outlined dense />
          <v-text-field
            label="Date of Birth"
            v-model="form.birthdate"
            dense
            outlined
          />
          <v-text-field label="Gender" v-model="form.gender" dense outlined />

          <v-textarea
            label="About You"
            v-model="form.zoneinfo"
            dense
            outlined
          />
          <v-btn
            color="#FF3C11 white--text"
            width="100%"
            type="submit"
            @click="saveProfileData"
            outlined
            >Save</v-btn
          >
        </v-col>

        <v-col cols="5">
          <div class="image-field">
            <div class="image-input">
              <div class="placeholder">
                <!--<v-icon v-if="!form.picture" size="100">fas fa-user</v-icon>-->
                <img ref="fileInput" :src="form.picture" aspect-ratio="1" />
              </div>
              <div class="image-target-wrapper">
                <v-file-input
                  hide-input
                  class="image-target"
                  v-model="files"
                  @change="handleUpdate"
                />
              </div>
            </div>

            <div>Upload a Photo</div>
            <div>File size should be less than 1 MB</div>
          </div>
          <div class="text-center mt-8">
            <canvas id="qrCode" class="rounded-lg"></canvas>
            <div class="mt-5">Scan QR Code to Launch the Mobile App</div>
          </div>
        </v-col>
        <loader-component v-if="updated"></loader-component>
        <snackbar-component
          v-if="notify"
          :show="notify"
          :text="notification"
          :color="notificationColor"
          @closeNotify="closeNotify"
        >
        </snackbar-component>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import awsAuth from "@/cognito/aws-auth";
import request from "@/plugins/request";
import loader from "@/config.loader";
import ProfileHeader from "./ProfileHeader";
import LoaderComponent from "@/components/OverlayComponent";
import SnackbarComponent from "@/components/SnackbarComponent";
import QRCode from "qrcode";

export default {
  components: {
    ProfileHeader,
    LoaderComponent,
    SnackbarComponent,
  },
  data() {
    return {
      form: {},
      updated: false,
      notify: false,
      notification: "",
      notificationColor: "",
      avatar: "",
      files: null,
    };
  },
  mounted() {
    this.displayQR();
    this.$nextTick(() => {
      this.getProfileData();
    });
  },
  methods: {
    displayQR() {
      let canvas = document.getElementById("qrCode");
      if (canvas == null) return;
      let link = localStorage.getItem("dynamic-link");

      QRCode.toCanvas(
        canvas,
        link,
        {
          width: 220,
          margin: 2,
        },
        function (error) {
          if (error) console.error(error);
        }
      );
    },
    getProfileData() {
      this.updated = true;
      awsAuth.userData().then((response) => {
        this.form = {
          name: response.data.nickname ? response.data.nickname : "",
          fullname: response.data.nickname ? response.data.nickname : "",
          birthdate: response.data.birthdate ? response.data.birthdate : "",
          email: response.data.email,
          picture: response.data.picture ? response.data.picture : "",
          gender: response.data.gender ? response.data.gender : "",
          zoneinfo: response.data.zoneinfo ? response.data.zoneinfo : "",
        };
        this.updated = false;
      });
    },
    handleUpdate(file) {
      this.files = file;
      const fileReader = new FileReader();
      let self = this;
      fileReader.onloadend = (e) => {
        console.log(e.target.name);
        const picture = fileReader.result;
        self.avatar = this.files.name;
        let imgRef = self.$refs.fileInput;
        imgRef.src = picture;
      };
      fileReader.readAsDataURL(this.files);
      this.save();
    },
    save() {
      this.updated = true;

      let formData = new FormData();
      formData.append("file", this.files);
      request(
        {
          method: "POST",
          url: `/Profile/SaveAvatar?name=${this.avatar}`,
          body: formData,
          type: "multipart/form-data",
        },
        this.$store
      )
        .then((result) => {
          if (result.status == 200) {
            this.form.picture = `${loader.getConfigValue(
              "VUE_APP_API_URL"
            )}/Profile/GetAvatars`;
          }
          this.updateAttr();
        })
        .catch((error) => {
          this.form.picture = error.message;
          console.log(error);
          this.updateAttr();
        });
    },
    saveProfileData() {
      let data = {
        name: this.form.name,
        nickname: this.form.fullname,
        birthdate: this.form.birthdate,
        email: this.form.email,
        gender: this.form.gender,
        zoneinfo: this.form.zoneinfo,
      };

      awsAuth
        .updateAttributes(data)
        .then((result) => {
          this.notify = true;
          this.notification = `${result.data}, profile updated!`;
          this.updated = false;
        })
        .catch((error) => {
          this.notify = true;
          this.notification = `ERROR, ${error.message}!`;
          this.notificationColor = "yellow darken-4";
          this.updated = false;
        });
    },
    updateAttr() {
      let data = {
        name: this.form.name,
        birthdate: this.form.birthdate,
        gender: this.form.gender,
        zoneinfo: this.form.zoneinfo,
        picture: this.form.picture,
      };
      awsAuth
        .updateAttributes(data)
        .then((result) => {
          this.notify = true;
          this.notification = `${result.data}, profile updated!`;
          this.updated = false;
        })
        .catch((error) => {
          this.notify = true;
          this.notification = `ERROR, ${error.message}!`;
          this.notificationColor = "yellow darken-4";
          this.updated = false;
        });
    },
    closeNotify(notify) {
      this.notify = notify;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn.v-btn--has-bg {
  background-color: #ff3c11;
}

.v-btn {
  background-color: #ff3c11 !important;
  color: white !important;
  border: none;
}

.fields {
  display: flex;
  flex-flow: column nowrap;

  align-items: stretch;

  & > *:last-child {
    align-self: flex-end;
  }
}

.image-field {
  display: flex;
  flex-flow: column nowrap;

  justify-content: center;
  align-items: center;
}

.image-input {
  display: flex;
  flex-flow: column nowrap;

  justify-content: center;
  align-items: center;

  position: relative;

  padding: 1rem;

  .image-target-wrapper {
    position: absolute;

    bottom: 10px;
    right: 25px;

    border-radius: 50%;

    background-color: #eee;

    width: 40px;
    height: 40px;

    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    align-items: flex-end;

    .image-target > * {
      margin: 0 !important;
    }

    .v-text-field {
      margin: 0 !important;
      padding: 0 !important;
    }
  }

  .placeholder {
    border-radius: 50%;

    width: 170px;
    height: 170px;

    background-color: #ccc;

    display: flex;
    flex-flow: column nowrap;

    justify-content: center;
    align-items: center;
  }
}
</style>
